import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Link from 'components/Link'

const Footer = ({
  data: {
    allFooterLinksYaml: { edges },
  },
}) => (
  <footer className="footer">
    <div className="container">
      <div className="row">
        {edges.map(({ node }) => (
          <div
            className="col-xs-4 col-md-2 col-sm-3 footer__item m-t-15"
            key={node.name}
          >
            <h3 className="footer__title">{node.name}</h3>
            <ul className="footer__list">
              {node.links.map(link => (
                <li className="footer__list-item" key={link.name}>
                  <Link
                    data-track
                    data-track-location="footer"
                    to={link.url}
                    className="footer__list-link"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="row footer__copyrights">
        <div className="col-md-6">
          © UseData 2019.{' '}
          <Link
            data-track
            data-track-location="footer"
            to="/terms"
            className="footer__list-link"
          >
            Terms
          </Link>{' '}
          |
          <Link
            data-track
            data-track-location="footer"
            to="/privacy"
            className="footer__list-link"
          >
            {' '}
            Privacy Policy
          </Link>          
        </div>
        <div className="col-lg-4 col-lg-offset-2 col-md-5 col-md-offset-1 col-sm-3 footer__item--other text-right">
          <ul className="footer__list">
            <li className="footer__list-item">
              <Link
                to="https://twitter.com/UseDataInsights"
                className="footer__list-link footer__socialicons"
                target="_blank"
              >
                <i className="fa fa-twitter-square fa-2x" aria-hidden="true" />
              </Link>
              <Link
                to="https://www.linkedin.com/company/usedata/about"
                className="footer__list-link footer__socialicons"
              >
                <i className="fa fa-linkedin-square fa-2x" aria-hidden="true" />
              </Link>
              <Link
                to="https://www.facebook.com/usedata.vn"
                className="footer__list-link footer__socialicons"
              >
                <i className="fa fa-facebook-square fa-2x" aria-hidden="true" />
              </Link>
              <Link
                to="https://www.youtube.com/channel/UCFQHOHZf8VP014VqGorGlmA"
                className="footer__list-link"
              >
                <i className="fa fa-youtube-square fa-2x" aria-hidden="true" />
              </Link>                                                        
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
)

export default props => (
  <StaticQuery
    query={graphql`
      {
        allFooterLinksYaml {
          edges {
            node {
              name
              links {
                name
                url
              }
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
